import { Text, TextProps, useColorModeValue } from "@chakra-ui/react"
import React, { FC } from "react"

type Props = TextProps & {
  colorScheme: "" | "red" | "green" | "blue" | "orange" | "purple"
}

/**
 * Normal <Text /> component doesn't seem to support colorScheme. Maybe its a bug?
 * `colorScheme` is in the list of props though:
 * https://chakra-ui.com/docs/typography/text#props
 */
export const ColoredText: FC<Props> = ({ colorScheme, children, ...props }) => {
  const color = useColorModeValue(colorScheme + ".500", colorScheme + ".200")
  return (
    <Text color={color} {...props}>
      {children}
    </Text>
  )
}
