import { Link, SlideFade } from "@chakra-ui/react"
import React, { FC } from "react"
import { ColoredText } from "../ColoredText"
import { ErrorBoundary } from "../ErrorBoundary"
import { FormBox, FormHeading, FormVStack } from "../Form"
import { PageTitle } from "../typing-test/PageTitle"
import { Layout } from "./Layout"
import { LayoutBody } from "./LayoutBody"
import { Nav } from "./Nav"

const FormLayoutBare: FC = ({ children }) => {
  return (
    <Layout>
      <Nav showBottomBorder={true} />
      <LayoutBody size="md">{children}</LayoutBody>
    </Layout>
  )
}

export const FormLayout: FC<{
  title: string
  showBack?: boolean
}> = ({ title, showBack = false, children }) => {
  return (
    <FormLayoutBare>
      <PageTitle>{title}</PageTitle>
      <SlideFade in>
        <FormBox>
          <FormVStack>
            {showBack && (
              <ColoredText colorScheme="blue" fontWeight="bold" fontSize="lg">
                <Link onClick={() => window.history.back()} textDecor="none">
                  ← Back
                </Link>
              </ColoredText>
            )}
            <FormHeading>{title}</FormHeading>
            <ErrorBoundary>{children}</ErrorBoundary>
          </FormVStack>
        </FormBox>
      </SlideFade>
    </FormLayoutBare>
  )
}
