import React, { FC } from "react"
import {
  Heading,
  VStack,
  Box,
  useColorModeValue,
  StackProps,
} from "@chakra-ui/react"

export const FormVStack: FC<StackProps> = ({ children, ...props }) => {
  return (
    // "stretch" makes buttons take up full width by default
    <VStack spacing={6} align="stretch" {...props}>
      {children}
    </VStack>
  )
}

export const FormHeading: FC = ({ children }) => {
  return <Heading>{children}</Heading>
}

export const FormBox: FC = ({ children }) => {
  const borderColor = useColorModeValue("gray.200", "gray.700")

  return (
    <Box
      border="1px solid"
      borderColor={borderColor}
      p={8}
      borderRadius="2xl"
      boxShadow="sm"
    >
      {children}
    </Box>
  )
}
