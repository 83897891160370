import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Link } from "@chakra-ui/react"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React from "react"
import { config } from "../config"

const subject = "Help with Typing Test"

// prettier-ignore
const body = `📝 What happened:


📝 What I expected to happen:


💻 About my system:
Web browser:
Operating system:
Device:
`

/** Especially useful in places where the user might run into technical issues or get upset */
export function HelpLink() {
  const mixpanel = useMixpanel()

  return (
    <Link
      onClick={() => mixpanel.track("Click help link")}
      href={`mailto:${config.HELP_EMAIL}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`}
      isExternal
    >
      Need Help? <ExternalLinkIcon mt="-2px" />
    </Link>
  )
}
