/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import { Fragment, FC } from "react"
import { Footer } from "./Footer"
import { ErrorBoundary } from "../ErrorBoundary"
import { useQueryStringToast } from "../queryStringToast"

export const Layout: FC = ({ children }) => {
  useQueryStringToast()

  return (
    <Fragment>
      <div
        css={css`
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        `}
      >
        {children}
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      </div>
    </Fragment>
  )
}
