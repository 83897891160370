/** @jsx jsx */
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  HStack,
  Text,
  useColorModeValue,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { css, jsx } from "@emotion/react"
import firebase from "firebase/app"
import { Link as GatsbyLink } from "gatsby"
import { FC, useState } from "react"
import {
  Authenticated,
  useAuthenticated,
} from "../../components/auth/Authenticated"
import { DebugOnly } from "../../components/debug/DebugContext"
import { DisabledWhenLoading } from "../../components/DisabledWhenLoading"
import { FormVStack } from "../../components/Form"
import { HelpLink } from "../../components/HelpLink"
import { FormLayout } from "../../components/layout/FormLayout"

const SettingLink: FC<{ label: string; to: string }> = ({
  label,
  to,
  children,
}) => {
  const bg = useColorModeValue("gray.100", "gray.700")

  return (
    <div style={{ marginTop: -1, marginBottom: -1 }}>
      <GatsbyLink
        to={to}
        css={css`
          text-align: left;
          width: 100%;
          outline: 0;
        `}
      >
        <Box
          p={4}
          mx={-4}
          rounded="md"
          _hover={{ bg }}
          display="flex"
          alignItems="center"
        >
          <Box flexGrow={1}>
            <Text fontWeight="semibold" mb={1}>
              {label}
            </Text>
            {children}
          </Box>
          {/* <Text opacity={0.4} fontSize="lg">

          </Text> */}
        </Box>
      </GatsbyLink>
    </div>
  )
}

const SettingItem: FC<{ label: string }> = ({ label, children }) => {
  return (
    <div style={{ marginTop: -1, marginBottom: -1 }}>
      <Box p={4} mx={-4} rounded="md" display="flex">
        <VStack spacing={2} alignItems="start">
          <Text fontWeight="semibold">{label}</Text>
          {children}
        </VStack>
      </Box>
    </div>
  )
}

function reauthenticateTo(url: string) {
  return `/account-settings/reauthenticate?to=${encodeURIComponent(url)}`
}

function DeleteAccountLink() {
  return (
    <SettingLink
      to="/account-settings/delete-account"
      label="☠️ Delete your account"
    >
      ...and all information associated with it.
    </SettingLink>
  )
}

/**
 * Allow unlinking accounts
 *
 * If user creates a password account, logs out, then logs in using Google,
 * then the accounts are automatically merged
 */
function LinkedAccounts() {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useAuthenticated()
  const toast = useToast()

  function unlink(provider: firebase.UserInfo | null) {
    setIsLoading(true)
    if (!provider) throw new Error("Provider should always exist")
    user
      .unlink(provider.providerId)
      .then(() => {
        toast({
          title: `Unlinked "${provider.providerId}" account.`,
        })
        // Updating this state causes this component to reload and pull the latest user state too
        setIsLoading(false)
      })
      .catch(error => {
        toast({
          title: `Error unlinking "${provider.providerId}" account.`,
        })
        setIsLoading(false)
        throw new Error(error)
      })
  }

  // Don't want to unlink if only 1 account
  if (user.providerData.length < 2) return null

  return (
    <SettingItem label="Linked accounts">
      <DisabledWhenLoading isLoading={isLoading}>
        <Wrap spacing={2}>
          {user.providerData.map(provider => (
            <WrapItem>
              <Button size="sm" onClick={() => unlink(provider)}>
                Unlink {provider?.providerId}
              </Button>
            </WrapItem>
          ))}
        </Wrap>
      </DisabledWhenLoading>
    </SettingItem>
  )
}

function EmailAccountSettings() {
  const { user } = useAuthenticated()

  return (
    <FormVStack spacing={0} divider={<Divider />}>
      <SettingItem label="Email">
        <div>{user.email}</div>
        {/* Putting this here mostly for debugging purposes */}
        <HStack spacing={2}>
          {!user.emailVerified && (
            <Button
              size="sm"
              colorScheme="orange"
              as={GatsbyLink}
              to="/account/verify-email"
            >
              Verify Email →
            </Button>
          )}
          <Button
            size="sm"
            as={GatsbyLink}
            to={reauthenticateTo("/account-settings/update-email")}
          >
            Update →
          </Button>
        </HStack>
      </SettingItem>
      <SettingItem label="Password">
        <div>{"•".repeat(16)}</div>
        <Button
          size="sm"
          as={GatsbyLink}
          to={reauthenticateTo("/account-settings/update-password")}
        >
          Update →
        </Button>
      </SettingItem>
      {null}
      {null}
      <LinkedAccounts />
      <DeleteAccountLink />
    </FormVStack>
  )
}

function GoogleAccountSettings() {
  return (
    <FormVStack spacing={0} divider={<Divider />}>
      <DeleteAccountLink />
    </FormVStack>
  )
}

function AccountSettingsContents() {
  const { user } = useAuthenticated()

  const hasPassword = user.providerData.find(d => d?.providerId === "password")

  if (hasPassword) {
    return <EmailAccountSettings />
  }

  const hasGoogle = user.providerData.find(d => d?.providerId === "google.com")

  if (hasGoogle) {
    return <GoogleAccountSettings />
  }

  return (
    <Alert status="warning">
      <AlertIcon />
      <Box flex="1">
        <AlertTitle>No Settings Available</AlertTitle>
        <HelpLink />
      </Box>
    </Alert>
  )
}

export default function AccountSettings() {
  return (
    <FormLayout title="⚙️ Account Settings">
      <Authenticated>
        <AccountSettingsContents />
      </Authenticated>
    </FormLayout>
  )
}
