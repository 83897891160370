import React from "react"
import { Box, Flex, Link, Spacer, useColorModeValue } from "@chakra-ui/react"
import { HelpLink } from "../HelpLink"
import { ToggleDarkModeSwitch } from "../ToggleDarkModeSwitch"
import { config } from "../../config"

export function Footer() {
  const borderColor = useColorModeValue("gray.200", "gray.700")

  return (
    <Flex
      borderTop="1px solid"
      borderColor={borderColor}
      py={2}
      mx={4}
      fontSize="sm"
      color="gray.500"
      flexDir={["column", "column", "row"]}
    >
      <Box display={["block", "block", "none"]}>
        <ToggleDarkModeSwitch />
      </Box>
      {config.SITE_NAME} © {new Date().getFullYear()}
      <Spacer />
      <span>
        Made in Sacramento, CA by{" "}
        <Link href="https://markmiro.com" isExternal>
          Mark Miro
        </Link>
      </span>
      <Spacer />
      <HelpLink />
    </Flex>
  )
}
